"use client";

import { ProductCategory } from "@medusajs/medusa";
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { useCart, useProducts } from "medusa-react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";

import Breadcrumb from "@/components/Breadcrumb";
import { SortButton } from "@/components/Button/SortButton";
import { Categories } from "@/components/pages/category/index";
import { Pagination, ViewedProducts } from "@/components/Pagination";
import { ProductCard } from "@/components/Product/ProductCard";
import ProductCardSkeleton from "@/components/Skeleton/Product/Card";
import { loadBreadcrumbs } from "@/data/Breadcrumbs/fetch-breadcrumbs";
import { usePagination } from "@/lib/hooks/use-pagination";
import { ProductPreviewType } from "@/types/global";

interface ProductsInterface {
  category: ProductCategory;
}

export const ProductListingTitle = ({ startedAt, endedAt }: { startedAt?: string; endedAt?: string }) => {
  if (startedAt && endedAt) {
    return <p className="py-2 text-center text-white font-bold">{`Angebote vom ${startedAt} - ${endedAt}`}</p>;
  }

  return (
    <div className="flex flex-col sm:flex-row uppercase">
      <span className="text-kodi-blue text-3xl font-bold">Angebote vom </span>
      <span className="text-kodi-blue text-3xl sm:ml-2">
        {moment().startOf("isoWeek").format("DD.MM.YYYY")}. bis {moment().endOf("isoWeek").subtract(1, "days").format("DD.MM.YYYY")}.
      </span>
    </div>
  );
};

const prepareCategoryIds = (category: ProductCategory) => {
  const children = category.category_children.map((subCategory) => subCategory.id);

  return [category.id, ...children];
};

export const Products = ({ category }: ProductsInterface) => {
  const [sort, setSort] = useState<string>("");
  const { cart } = useCart();
  const { getPageNumber, getOffset, getLimit } = usePagination(21);
  const { products, limit, count, isLoading } = useProducts(
    {
      category_id: prepareCategoryIds(category),
      region_id: cart?.region_id,
      limit: getLimit(),
      offset: getOffset(),
      order: sort.length ? sort : "title",
    },
    { enabled: !!cart?.region_id },
  );

  return (
    <>
      <div className="mb-10 pl-4 lg:block hidden">
        <CategoryBreadCrumbs currentCategory={category} />
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-span-3 p-4 mt-12 lg:block hidden">
          {category?.category_children && <Categories categories={category?.category_children} />}
        </div>
        <div className="col-span-12 lg:col-span-9 px-4">
          <p className="text-kodi-blue text-3xl font-bold uppercase">{category.name}</p>

          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12 mt-3 border-y border-gray-300">
              <SortButton setSort={setSort} />
            </div>

            <RenderProducts products={products} isLoading={isLoading} />
          </div>

          <div className="flex justify-between flex-wrap mt-14">
            <div>
              <ViewedProducts limit={getLimit()} pageNumber={getPageNumber()} totals={count ?? 0} />
            </div>
            <Pagination limit={limit} totals={count} />
          </div>
        </div>
      </div>
    </>
  );
};

const RenderProducts = ({ products, isLoading }: { products?: PricedProduct[]; isLoading: boolean }) => {
  if (isLoading) {
    return <RenderSkeleton />;
  }

  if (!products?.length) {
    return (
      <div className="col-span-12">
        <p className="ml-5">Keine Produkte gefunden</p>
      </div>
    );
  }

  return (
    <>
      {products?.map((product, index) => (
        <div className="col-span-6 lg:col-span-4 border rounded-3xl" key={index}>
          <ProductCard className="" product={product as ProductPreviewType} />
        </div>
      ))}
    </>
  );
};

export const RenderSkeleton = () => {
  const items = [];

  for (let i = 0; i < 3; i++) {
    items.push(
      <div className="col-span-12 md:col-span-6 lg:col-span-4 border" key={i}>
        <ProductCardSkeleton />
      </div>,
    );
  }

  return <>{items}</>;
};

const CategoryBreadCrumbs = ({ currentCategory }: { currentCategory: ProductCategory }) => {
  const [data, setData] = useState<ProductCategory | undefined>(undefined);

  useEffect(() => {
    loadBreadcrumbs(currentCategory.id).then((res) => setData(res));
  }, [currentCategory]);

  return (
    <div>
      <Breadcrumb product_category={data} />
    </div>
  );
};
