import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/htdocs/eshop/releases/20250113112113/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["FooterItemMenu"] */ "/var/www/htdocs/eshop/releases/20250113112113/src/components/Footer/components/FooterItemMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/htdocs/eshop/releases/20250113112113/src/components/HeaderNotice.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/htdocs/eshop/releases/20250113112113/src/components/Menu/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar"] */ "/var/www/htdocs/eshop/releases/20250113112113/src/components/Menu/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FlyoutPortal"] */ "/var/www/htdocs/eshop/releases/20250113112113/src/components/Modal/Flyout.tsx");
