"use client";

import { useRouter } from "next/navigation";
import { useEffect } from "react";

interface RedirectInterface {
  link: string;
}

/**
 * @description non render component
 * @param component
 * @constructor
 */
export const RedirectComponent = ({ ...component }: RedirectInterface) => {
  const router = useRouter();

  useEffect(() => {
    if (component.link) {
      router.push(component.link);
    }
  }, [component]);

  return <></>;
};
