"use client";

import { useCart, useProducts } from "medusa-react";
import React from "react";

import { SliderProduct } from "@/components/Slider/Product/components/SlideProduct";

export const ProvideSliderProduct = ({ ids }: { ids: string[] }) => {
  const { cart } = useCart();
  const { products } = useProducts(
    {
      id: ids,
      region_id: cart?.region_id,
      expand: "variants,variants.prices",
    },
    { enabled: !!cart?.region_id },
  );

  if (!ids.length) {
    return <></>;
  }

  return (
    <>
      {products?.map((product, index) => {
        return <SliderProduct product={product} key={index} isFirstItem={index === 0} isLastItem={index + 1 < products.length} />;
      })}
    </>
  );
};
