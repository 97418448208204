import Link from "next/link";

import ProductFlags, { ProductFlagPosition } from "@/components/Product/components/ProductFlags";
import { ProductPrice } from "@/components/Product/components/ProductPrice";
import { PlaceholderImageString, ProductImage } from "@/components/Product/ProductImage";
import { WishlistProductIcon } from "@/components/Wishlist/WishlistProductIcon";
import { ProductPreviewType } from "@/types/global";

interface ProductCardInterface {
  product: ProductPreviewType;
  className?: string;
  onClick?: () => void;
  isDemo?: boolean;
}

export const ProductCard = ({ product, className, onClick }: ProductCardInterface) => {
  const productDetailLink = `/p/${product.handle}`;
  const productThumbnail = product.thumbnail ?? PlaceholderImageString;

  return (
    <div className={`relative flex flex-col w-full rounded-3xl border-kodi-gray-600 overflow-hidden ${className}`}>
      <div className="absolute top-2 right-2 px-2 z-10">
        <WishlistProductIcon product_id={product.id as string} className="stroke-gray-600 fill-transparent" inWishlistClassName="!fill-gray-600" />
      </div>

      <Link href={productDetailLink} className={`flex flex-col w-full`} onClick={onClick}>
        <ProductFlags product={product} position={ProductFlagPosition.topLeft} />
        <ProductFlags product={product} position={ProductFlagPosition.bottomRight} />

        {productDetailLink && productThumbnail && (
          <div className="relative">
            <div className="absolute inset-0 bg-black opacity-[03%] rounded-t-3xl"></div>
            <ProductImage
              src={productThumbnail}
              fallbackimage={PlaceholderImageString}
              className="w-[auto] mx-auto h-[10rem] md:h-[12rem] lg:h-[15rem] object-contain"
              width={200}
              height={1000}
              alt={product.title ?? ""}
            />
          </div>
        )}

        {!productDetailLink && productThumbnail && (
          <ProductImage src={productThumbnail} fallbackimage={PlaceholderImageString} width={400} height={540} alt={product.title ?? ""} />
        )}

        <div className="py-4 px-4">
          <p className="min-h-[50px] md:min-h-[60px] text-kodi-blue text-md sm:text-lg font-bold uppercase line-clamp-2">{product.title ?? ""}</p>
          <div className="flex justify-end items-center mb-5 md:mb-0 w-full sm:min-h-[88px] lg:min-h-[108px]">
            <ProductPrice product={product} />
          </div>
        </div>
      </Link>
    </div>
  );
};
